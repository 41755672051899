<template>
  <div class="bbr-admin--page">
    <main-app-bar :showSearchIcon="false">
      <template v-slot:title> Admin {{ pageType }} </template>
    </main-app-bar>

    <div class="mt-5 px-12 mb-10">
      <router-view />
    </div>
  </div>
</template>

<script>
import MainAppBar from '@/layouts/shared/MainAppBar'

export default {
  name: 'AdminPage',

  components: {
    MainAppBar,
  },

  computed: {
    pageType() {
      return this.$route.meta.pageType
    },
  },
}
</script>
